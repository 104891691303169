import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { useElementOnScreen } from '@hooks';
import { DEFAULT_DIMENSIONS, IDimensions, ISticker, SizeCalculationBase } from '@typings';

import { Image, Video } from './components';
import s from './Sticker.scss';


interface StickerProps {
    className?: string;
    loop?: boolean;
    withSound?: boolean;
    soundOnHover?: boolean;
    overlay?: JSX.Element;
    sticker: ISticker;
    useFadeInOnLoaded?: boolean;
    useSkeletonOnLoading?: boolean;
    useAnimatedPreview?: boolean;
    volume?: number;
    videoRef?: React.MutableRefObject<HTMLVideoElement | null | undefined>;
    useFallbackVideo?: boolean;
    sizeCalculationBase?: SizeCalculationBase;
    onClick?: (sticker: ISticker) => void;
    onLoaded?: (sticker: ISticker) => void;
}

export const Sticker = (props: StickerProps) => {
    const {
        className,
        loop,
        withSound,
        soundOnHover = false,
        overlay,
        sizeCalculationBase = SizeCalculationBase.NONE,
        sticker,
        useFallbackVideo = false,
        useFadeInOnLoaded = false,
        useSkeletonOnLoading = false,
        useAnimatedPreview = false,
        volume,
        videoRef,
        onClick = noop,
        onLoaded = noop,
    } = props;

    const stickerSourceUrl = useFallbackVideo ? sticker.fallbackUrl : sticker.stickerUrl;
    const stickerDisabled = sticker.customSettings?.disableSticker || sticker.moderationState.toLowerCase() !== 'approved';

    const stickerVideoRef = useRef<HTMLVideoElement>(null);
    const stickerImageRef = useRef<HTMLImageElement>(null);

    const { elementRef: stickerRef, isOnScreen: isStickerOnScreen } = useElementOnScreen<HTMLDivElement>({
        threshold: 0,
        throttleTimeMs: 100,
        callback: (videoIsOnScreen) => {
            if (stickerVideoRef.current) {
                if (videoIsOnScreen && stickerSourceUrl) {
                    stickerVideoRef.current.src = stickerSourceUrl;
                    stickerVideoRef.current.load();
                } else {
                    stickerVideoRef.current.src = '';
                    stickerVideoRef.current.load();
                }
            }

            if (stickerImageRef.current) {
                if (videoIsOnScreen && stickerSourceUrl) {
                    stickerImageRef.current.src = stickerSourceUrl;
                } else {
                    stickerImageRef.current.src = '';
                }
            }
        },
    });

    const [ready, setReady] = useState(false);
    const [videoDims, setVideoDims] = useState<IDimensions>(DEFAULT_DIMENSIONS);
    const [isHovered, setIsHovered] = useState(false);

    const onReadyStateChanged = (ready: boolean) => {
        setReady(ready);
        onLoaded(sticker);
    };

    const stickerView = useAnimatedPreview
        ? <Image
            source={sticker.stickerAnimatedPreview}
            stickerImageRef={stickerImageRef}
            onReadyStateChange={onReadyStateChanged}
        />
        : <Video
            source={isStickerOnScreen ? stickerSourceUrl : ''}
            loop={loop}
            isHovered={isHovered}
            restartOnHover={soundOnHover}
            disabled={stickerDisabled}
            volume={sticker.customSettings?.customVolume || volume}
            withSound={soundOnHover ? withSound && isHovered : withSound}
            videoRef={videoRef}
            stickerVideoRef={stickerVideoRef}
            onReadyStateChange={onReadyStateChanged}
        />;

    const recalculateDimensions = () => {
        switch (sizeCalculationBase) {
            case SizeCalculationBase.WIDTH:
                if (stickerRef.current?.clientWidth && sticker.videoData) {
                    setVideoDims({
                        width: stickerRef.current.clientWidth,
                        height: stickerRef.current.clientWidth / sticker.videoData.aspectRatio,
                    });
                }

                break;
            case SizeCalculationBase.HEIGHT:
                if (stickerRef.current?.clientHeight && sticker.videoData) {
                    setVideoDims({
                        width: stickerRef.current.clientHeight * sticker.videoData.aspectRatio,
                        height: stickerRef.current.clientHeight,
                    });
                }

                break;
        }
    };

    useEffect(() => {
        recalculateDimensions();
    }, []);

    return (
        <div
            ref={stickerRef}
            className={cn(
                s.sticker,
                className,
                {
                    [s.stickerLoading]: !ready && useSkeletonOnLoading,
                    [s.stickerLoaded]: ready && useFadeInOnLoaded,
                },
            )}
            style={videoDims.width ? videoDims : { minHeight: '140px' }}
            onClick={() => onClick(sticker)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onMouseMove={() => setIsHovered(true)}
        >
            { isStickerOnScreen && stickerView }
            { overlay }
            { ready && !isHovered && sticker.moderationState.toLowerCase() === 'moderation' &&
                <div className={s.moderationPlate}>{t('personal-area.mine.moderation')}</div>
            }
            { ready && !isHovered && sticker.moderationState.toLowerCase() === 'declined' &&
                <div className={s.declinedPlate}>{t('personal-area.mine.rejected')}</div>
            }
            { ready && !isHovered && sticker.customSettings?.disableSticker &&
                <div className={s.blacklistPlate}>{t('personal-area.mine.blacklisted')}</div>
            }
        </div>
    );
};
