// eslint-disable-next-line import/no-unresolved
import config from 'config';
import { useEffect, useRef, useState } from 'react';

import { useApi, useCreatorApi, useCurrentUser, usePlayingStickersList } from '@hooks';
import {
    IGame,
    IMemeCreatorResult,
    IPreparedResult,
    IPreviewResult,
    ISticker,
    ITag,
    IUser,
    StickerCreationStage,
    StickerSize,
    TaskState,
} from '@typings';


export const useStickerCreation = () => {
    const {
        stickers: {
            useCreateStickerMutation,
        },
    } = useApi();

    const {
        useLazyRunPrepareTaskQuery,
        useLazyRunPreviewTaskQuery,
        useLazyRunCompleteTaskQuery,
        useLazyCheckTaskResultQuery,
    } = useCreatorApi();

    const {
        currentUser,
    } = useCurrentUser();

    const [runPrepareTask] = useLazyRunPrepareTaskQuery();
    const [runPreviewTask] = useLazyRunPreviewTaskQuery();
    const [runCompleteTask] = useLazyRunCompleteTaskQuery();
    const [checkTaskResult] = useLazyCheckTaskResultQuery();
    const [create] = useCreateStickerMutation();

    const [stickerSize, setStickerSize] = useState<string>(StickerSize.MEDIUM);
    const [availableAreaMask, setAvailableAreaMask] = useState(511);

    const [taskId, setTaskId] = useState<string>();
    const [taskResult, setTaskResult] = useState<IMemeCreatorResult>();

    const [isPrepareTaskInProcess, setPrepareTaskInProcess] = useState(false);
    const [isPreviewTaskInProcess, setPreviewTaskInProcess] = useState(false);
    const [isStickerCreationInProcess, setIsStickerCreationInProcess] = useState(false);

    const [sticker, setSticker] = useState<ISticker>();
    const [stickerName, setStickerName] = useState<string>();
    const [stickerDescription, setStickerDescription] = useState<string>();
    const [audioFile, setAudioFile] = useState<File>();
    const [busyMessage, setBusyMessage] = useState('');
    const [imageFile, setImageFile] = useState<File>();
    const [isBusy, setIsBusy] = useState(false);
    const [overrideEmbeddedAudio, setOverrideEmbeddedAudio] = useState(false);
    const [preparedData, setPreparedData] = useState<IPreparedResult>();
    const [previewData, setPreviewData] = useState<IPreviewResult>();
    const [stage, setStage] = useState<StickerCreationStage>(StickerCreationStage.ADD_MEDIA);
    const [streamer, setStreamer] = useState<IUser>();
    const [game, setGame] = useState<IGame>();
    const [tags, setTags] = useState<ITag[]>();

    const previewRef = useRef<IPreviewResult>();

    const {
        list,
        addToList,
        removeFromList,
    } = usePlayingStickersList({
        stickerSize: config.obs.stickerSize[stickerSize ?? StickerSize.MEDIUM],
        availableAreaMask,
    });

    const switchStage = (stage: StickerCreationStage) => setStage(stage);

    const changeMedia = async (file: File) => {
        setPreviewData(undefined);
        setPrepareTaskInProcess(true);
        previewRef.current = undefined;
        setImageFile(file);

        const imageData = new FormData();
        imageData.append('image', file);
        const task = await runPrepareTask(imageData).unwrap();

        setTaskId(task.task_id);
        setTaskResult({
            id: task.task_id,
            state: TaskState.PENDING,
            status: TaskState.PENDING,
        });
    };

    const changeAudio = (file: File) => {
        setPreviewData(undefined);
        previewRef.current = undefined;
        setOverrideEmbeddedAudio(true);
        setAudioFile(file);
    };

    const checkForResult = async (taskId: string) => {
        const taskData = new FormData();
        taskData.append('id', taskId);
        const result = await checkTaskResult(taskData).unwrap();

        switch (result.state) {
            case TaskState.PENDING:
            case TaskState.STARTED:
            case TaskState.RETRY:
                setTimeout(() => checkForResult(taskId), 1000);
                break;
        }

        setTaskResult(result);

        if (result.state === TaskState.SUCCESS) {
            if (result.result?.preparedUrl) {
                setPreparedData(result.result);
                setPrepareTaskInProcess(false);
            }

            if (result.result?.previewUrl) {
                setPreviewData(result.result);
                setPreviewTaskInProcess(false);
                previewRef.current = result.result;
                addToList({
                    id: '',
                    stickerUrl: result.result.previewUrl,
                    creatorId: '',
                    name: '',
                    description: '',
                    // type: StickerType.GIF_AND_AUDIO,
                    moderationState: '',
                });
            }

            if (result.result?.token) {
                const sticker = await create({
                    name: stickerName ?? '',
                    description: stickerDescription ?? '',
                    streamerId: streamer?.id,
                    gameId: game?.id,
                    token: result.result.token,
                    tags,
                }).unwrap();

                setSticker(sticker);
                setIsStickerCreationInProcess(false);
            }
        } else if (result.state === TaskState.FAILURE) {
            setPrepareTaskInProcess(false);
            setPreviewTaskInProcess(false);
            setIsStickerCreationInProcess(false);
        }
    };

    const createStickerPreview = async () => {
        if (!preparedData) return;
        if (sticker) return sticker.stickerUrl;
        if (previewData) return previewData.previewUrl;

        setPreviewTaskInProcess(true);
        const stickerData = new FormData();
        stickerData.append('id', preparedData.id);

        if (!overrideEmbeddedAudio && preparedData.hasAudio) {
            stickerData.append('is_embedded', 'True');
        }

        if (audioFile) {
            stickerData.append('audio', audioFile);
        }

        const task = await runPreviewTask(stickerData).unwrap();

        setTaskId(task.task_id);
        setTaskResult({
            id: task.task_id,
            state: TaskState.PENDING,
            status: TaskState.PENDING,
        });
    };

    const createSticker = async (name: string, description?: string, streamer?: IUser, tags?: ITag[], game?: IGame) => {
        if (!preparedData) return;

        setIsStickerCreationInProcess(true);

        const stickerData = new FormData();
        stickerData.append('id', preparedData.id);

        if (!previewData) {
            if (!overrideEmbeddedAudio && preparedData.hasAudio) {
                stickerData.append('is_embedded', 'True');
            }

            if (audioFile) {
                stickerData.append('audio', audioFile);
            }
        }

        const task = await runCompleteTask(stickerData).unwrap();

        setTaskId(task.task_id);
        setTaskResult({
            id: task.task_id,
            state: TaskState.PENDING,
            status: TaskState.PENDING,
        });

        setStickerName(name);
        setStickerDescription(description);
        setStreamer(streamer);
        setGame(game);
        setTags(tags);
    };

    const resetState = () => {
        setPreparedData(undefined);
        setPreviewData(undefined);
        setSticker(undefined);
        setImageFile(undefined);
        setAudioFile(undefined);
        setPrepareTaskInProcess(false);
        setPreviewTaskInProcess(false);
        setIsStickerCreationInProcess(false);
        setOverrideEmbeddedAudio(false);
    };

    const playPreview = async () => {
        const url = await createStickerPreview();

        if (url) {
            addToList({
                id: '',
                stickerUrl: url,
                creatorId: '',
                name: '',
                description: '',
                moderationState: '',
            });
        }
    };

    const next = () => {
        if (stage === StickerCreationStage.ADD_AUDIO) {
            setStage(StickerCreationStage.ADD_DETAILS);
        }

        if (stage === StickerCreationStage.ADD_DETAILS) {
            setStage(StickerCreationStage.FINISH);
        }
    };

    const back = () => {
        if (stage === StickerCreationStage.ADD_AUDIO) {
            setImageFile(undefined);
            setPreparedData(undefined);
        }

        if (stage === StickerCreationStage.ADD_DETAILS) {
            setStage(StickerCreationStage.ADD_AUDIO);
        }
    };

    useEffect(() => {
        if (taskId) {
            setTimeout(() => checkForResult(taskId), 1000);
        }
    }, [taskId]);

    useEffect(() => {
        if (currentUser) {
            setStickerSize(currentUser.channel?.stickerSize ?? StickerSize.MEDIUM);
            setAvailableAreaMask(currentUser.channel?.stickerStickAreasMask ?? 511);
        }
    }, [currentUser]);

    useEffect(() => {
        if (!preparedData) {
            setStage(StickerCreationStage.ADD_MEDIA);
        } else if (preparedData && imageFile && !sticker) {
            setStage(StickerCreationStage.ADD_AUDIO);
        } else if (sticker) {
            setStage(StickerCreationStage.FINISH);
        }
    }, [preparedData, imageFile, sticker]);

    useEffect(() => {
        if (isPrepareTaskInProcess || isStickerCreationInProcess) {
            setIsBusy(true);

            switch (taskResult?.state) {
                case TaskState.PENDING:
                    setBusyMessage('В очереди');
                    break;
                case TaskState.STARTED:
                    setBusyMessage('Обработка');
                    break;
                case TaskState.SUCCESS:
                    setBusyMessage('Завершено');
                    break;
                default:
                    setBusyMessage('Ожидание');
                    break;
            }
        } else {
            setIsBusy(false);
            setBusyMessage('Ожидание');
        }
    }, [isPrepareTaskInProcess, isStickerCreationInProcess, taskResult]);

    return {
        audioFile,
        busyMessage,
        isBusy,
        isPreviewTaskInProcess,
        isStickerCreationInProcess,
        overrideEmbeddedAudio,
        playingStickersList: {
            list,
            addToList,
            removeFromList,
        },
        preparedData,
        previewData,
        stage,
        sticker,

        back,
        createSticker,
        changeAudio,
        changeMedia,
        next,
        playPreview,
        resetState,
        switchStage,
    };
};
