import cn from 'classnames';
import { t } from 'i18next';
import React from 'react';

import { ImageDefaultCurrency } from '@assets/images/png';
import { DATE_FORMAT_SHORT, declensionRubles, declensionUserCurrency } from '@common';
import { Avatar } from '@components';
import { ModerationState, EventKind, IEventData, IUser, BonusType } from '@typings';

import s from './EventsTable.scss';


interface EventsTableProps {
    data: Array<IEventData>;
    currentUser: IUser;

    onClick?: (e: IEventData) => void;
}

const getEventType = (event: IEventData) => {
    switch (event.kind) {
        case EventKind.STICKER_SENT:
            return event.fullscreen ? 'FullScreen' : 'Стикер';
        case EventKind.BONUS_EARNED:
        case EventKind.BONUS_ISSUED:
            return 'Бонус';
        case EventKind.BUY_CURRENCY:
            return 'Покупка';
        case EventKind.MEME_CANNON_STICKER_SENT:
            return 'Мем пушка';
        case EventKind.WELCOME_BONUS_EARNED:
            return 'Бонус';
        case EventKind.MODERATION_STATUS:
            return 'Модерация';
        default:
            return '-';
    }
};

const getEventRowClassname = (event: IEventData) => {
    switch (event.kind) {
        case EventKind.BONUS_EARNED:
        case EventKind.WELCOME_BONUS_EARNED:
        case EventKind.BONUS_ISSUED:
            return 'eventBgBonus';
        case EventKind.BUY_CURRENCY:
            return 'eventBgPurchase';
        case EventKind.STICKER_SENT:
            return event.fullscreen ? 'eventBgFullscreen' : 'eventBgSticker';
        case EventKind.MEME_CANNON_STICKER_SENT:
            return 'eventBgMemeCannon';
        default:
            return '';
    }
};

const getEventCellClassname = (event: IEventData) => {
    switch (event.kind) {
        case EventKind.STICKER_SENT:
            return event.fullscreen ? 'eventFgFullscreen' : 'eventFgSticker';
        case EventKind.MEME_CANNON_STICKER_SENT:
            return 'eventFgMemeCannon';
        default:
            return '';
    }
};

const getEventText = (event: IEventData, user: IUser) => {
    switch (event.kind) {
        case EventKind.STICKER_SENT:
            return event.message ? event.message : event.stickerName;
        case EventKind.BONUS_EARNED:
            return event.bonusType === BonusType.FIRST_PAYMENT ? 'Бонус на первый платеж' : 'Выдан бонус от стримера';
        case EventKind.BONUS_ISSUED:
            return event.bonusType === BonusType.FIRST_PAYMENT
                ? 'Получен бонус на первый платеж'
                : 'Получен бонус от стримера';
        case EventKind.BUY_CURRENCY:
            return `Покупка ${event.coins} ${
                declensionUserCurrency(event.coins!, user)
            } за ${event.packPrice} ${declensionRubles(event.packPrice!)}`;
        case EventKind.MEME_CANNON_STICKER_SENT:
            return event.message ? event.message : event.stickerName;
        case EventKind.WELCOME_BONUS_EARNED:
            return 'Welcome бонус';
        case EventKind.MODERATION_STATUS:
            return event.status === ModerationState.APPROVED
                ? `Стикер одобрен - ${event.stickerName}`
                : event.status === ModerationState.DECLINED
                    ? `Стикер отклонен - ${event.stickerName}${event.reason ? 'Причина: ' + event.reason : ''}`
                    : '-';
        default:
            return '-';
    }
};

export const EventsTable = (props: EventsTableProps) => {
    const {
        data = [],
        currentUser,
    } = props;

    const rows = data.map((e, idx) => {
        const eventType = getEventType(e);
        const rowClass = getEventRowClassname(e);
        const cellClass = getEventCellClassname(e);

        return (
            <tr key={idx} className={cn(s.eventsTableBodyRow, s[rowClass])}>
                <td className={s.eventsTableBodyRowCell}>{new Date(e.timestamp).toLocaleDateString('ru', DATE_FORMAT_SHORT)}</td>
                <td className={cn(s.eventsTableBodyRowCell, s[cellClass])}>{eventType}</td>
                <td className={s.eventsTableBodyRowCell}>{e.kind !== EventKind.MODERATION_STATUS ? e.coins : ''}</td>
                <td className={s.eventsTableBodyRowCell}>{getEventText(e, currentUser)}</td>
            </tr>
        );
    });

    const currencyIcon = <Avatar
        className={s.roundedAvatar}
        source={(currentUser?.channel?.currencyImageUrl || ImageDefaultCurrency)}
        width={12}
        height={12}
        rounded
    />;

    return (
        <table className={s.eventsTable}>
            <thead>
                <tr className={s.eventsTableHeadRow}>
                    <td className={s.eventsTableHeadRowCell}>{t('events.table.day')}</td>
                    <td className={s.eventsTableHeadRowCell}>{t('events.table.event')}</td>
                    <td className={s.eventsTableHeadRowCell}>
                        <div className={s.eventsTableHeadRowCellFlex}>
                            {currencyIcon}{t('events.table.coins')}
                        </div>
                    </td>
                    <td className={s.eventsTableHeadRowCell}>{t('events.table.info')}</td>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
    );
};
