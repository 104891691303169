export enum TaskState {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    RETRY = 'RETRY',
    STARTED = 'STARTED',
    PENDING = 'PENDING',
}

export interface IMemeCreatorTask {
    task_id: string;
}

export interface IMemeCreatorResult {
    id: string;
    state: TaskState;
    status: TaskState;
    result?: IPreparedResult & IPreviewResult & ICompleteResult;
}

export interface IPreparedResult {
    id: string;
    preparedUrl: string;
    hasAudio: string;
}

export interface IPreviewResult {
    id: string;
    previewUrl: string;
}

export interface ICompleteResult {
    token: string;
}
