import { Column, EColumnAlignment, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconCloseLine } from '@assets/images/svg';
import { TApp } from '@hooks';
import { Logo, LogoKind } from '@hyperclap/views';

import s from './JoinPopup.scss';

interface IJoinPopupProps extends TApp {}

export const JoinPopup = (props: IJoinPopupProps) => {
    const {
        deviceInfo: { isMobileLayoutRecommended },
    } = props;
    const { closeModal } = useModals();

    const handleCloseClick = () => closeModal();

    return (
        <div className={s.root}>
            <IconCloseLine
                className={
                    cn(
                        s.close,
                        { [s.closeMobile]: isMobileLayoutRecommended })
                }
                onClick={handleCloseClick}
            />

            <Column alignment={EColumnAlignment.CENTER}>
                <Logo kind={LogoKind.LIGHT} className={s.logo}/>

                <div className={s.text}>
                    Thank you for your interest in Hyperclap! We are currently operating in Closed Beta Testing. Please request an invite by contacting <a href="mailto:streamers@hyperclap.com">streamers@hyperclap.com</a>.
                </div>
            </Column>
        </div>
    );
};
