import { Button, EComponentColor, ETheme, ThemeContext, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer, Login, Logo, LogoKind } from '@hyperclap/views';

import videoBringUrl from './assets/bring/video.mp4';
import s from './Desktop.scss';
import { JoinPopup } from '../../components/JoinPopup';

/** Desktop layout of the landing properties */
interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const { changeTheme } = useContext(ThemeContext);
    const { showModal } = useModals();

    useLayoutEffect(
        () => {
            changeTheme(ETheme.HYPERCLAP, true);

            document.body.classList.add(s.body);

            window.addEventListener('keydown', handleWindowKeydown);

            return () => {
                document.body.classList.remove(s.body);

                window.removeEventListener('keydown', handleWindowKeydown);
            };
        },
        [],
    );

    const showAuth = () => {
        showModal({
            content: <Login {...props}/>,
            options: {
                bodyClassName: s.loginModalBody,
            },
        });
    };

    const handleJoinClick = () => {
        showModal({
            content: <JoinPopup {...props}/>,
            options: {
                bodyClassName: s.joinPopup,
            },
        });
    };

    const handleAuthClick = () => {
        showAuth();
    };

    const handleWindowKeydown = (event: KeyboardEvent) => {
        if (event.key.toLowerCase() === 'l' && event.shiftKey && (event.ctrlKey || event.metaKey)) {
            showAuth();
        }
    };

    return (
        <div className={s.root}>
            <div className={s.top}>
                <Logo kind={LogoKind.DARK}/>
            </div>

            <div className={s.bring}>
                <div className={s.bringTopBg}/>
                <div className={s.bringBottomBg}/>
                <div className={s.bringPolygon}/>

                <div className={s.bringKekw}>
                    <div className={s.bringKekwBg}/>

                    <video
                        className={s.bringKekwVideo}
                        autoPlay
                        loop
                        muted
                        src={videoBringUrl}
                    />

                    {/* <div className={s.bringKekwMeme1}/> */}
                    <div className={s.bringKekwMeme2}/>
                </div>

                <div className={s.bringDescription}>
                    <div className={s.bringDescriptionText}>
                        Let your community create and send video stickers to your stream. Goodbye boredom,
                        Hello laughs and epic moments!
                    </div>

                    <Button
                        className={s.bringButton}
                        caption="Join"
                        color={EComponentColor.ACCENT}
                        onClick={handleJoinClick}
                    />
                </div>
            </div>

            <div className={s.launch}>
                <div className={s.launchBg}/>

                <div className={s.launchStreamers}>
                    <div className={cn(s.launchStreamersItem, s.launchStreamersItem1)}>
                        <div className={s.hiddenAuth} onClick={handleAuthClick}/>
                    </div>
                    <div className={cn(s.launchStreamersItem, s.launchStreamersItem2)}/>
                    <div className={cn(s.launchStreamersItem, s.launchStreamersItem3)}/>
                    <div className={cn(s.launchStreamersItem, s.launchStreamersItem4)}/>
                    <div className={cn(s.launchStreamersItem, s.launchStreamersItem5)}/>
                    <div className={cn(s.launchStreamersItem, s.launchStreamersItem6)}/>
                </div>
                <div className={s.launchContent}>
                    <div className={s.launchContentTitle}>Launch YOUR Meme Party!</div>
                    <div className={s.launchContentText}>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Hyperclap not only offers thousands of memes but also lets viewers create new ones from your stream's fun moments.
                        Your stream will be loaded with local jokes, so your viewers will be cracking up.
                    </div>
                </div>
                <div className={s.launchCat}/>
            </div>

            <div className={s.moderation}>
                <div className={s.moderationLeft}>
                    <div className={cn(s.moderationBanana, s.moderationBanana1)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana2)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana3)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana4)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana5)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana6)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                </div>
                <div className={s.moderationCenter}>
                    <div className={s.moderationTitle}>STRICT MODERATION</div>
                    <div className={s.moderationText}>
                        No worries! Every sticker is
                        checked to follow Twitch’s rules,
                        and you control what’s allowed
                        and set the humor tone for your
                        community.
                    </div>
                </div>
                <div className={s.moderationRight}>
                    <div className={cn(s.moderationBanana, s.moderationBanana1)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana2)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana3)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana4)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana5)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                    <div className={cn(s.moderationBanana, s.moderationBanana6)}>
                        <div className={s.moderationBananaCross}/>
                    </div>
                </div>
            </div>

            <div className={s.reinvented}>
                <div className={s.reinventedBg}/>
                <div className={s.reinventedPolygon}/>

                <div className={s.reinventedContent}>
                    <div className={s.reinventedTitle}>Reinvented donations</div>
                    <div className={s.reinventedText}>
                        Viewers can create a <span className={s.reinventedTextStreamoji}>Streamoji</span>, their own virtual avatar that pops up when they donate, complete with a unique voice. They can also choose emotions and special effects to make it even more epic.
                    </div>
                </div>
            </div>

            <div className={s.money}>
                <div className={s.moneyBg}/>
                <div className={s.moneyPolygon}/>

                <div className={s.moneyCards}>
                    <div className={cn(s.moneyCardsItem, s.moneyCardsItem1)}/>
                    <div className={cn(s.moneyCardsItem, s.moneyCardsItem2)}/>
                    <div className={cn(s.moneyCardsItem, s.moneyCardsItem3)}/>
                </div>

                {/* <div className={cn(s.moneyCoin, s.moneyCoin1)}/> */}
                <div className={cn(s.moneyCoin, s.moneyCoin2)}/>
                <div className={cn(s.moneyCoin, s.moneyCoin3)}/>
                <div className={cn(s.moneyCoin, s.moneyCoin4)}/>

                <div className={cn(s.moneyBgCoin, s.moneyBgCoin1)}/>
                <div className={cn(s.moneyBgCoin, s.moneyBgCoin2)}/>

                <div className={s.moneyGirl}/>

                <div className={s.moneyContent}>
                    <div className={s.moneyTitle}>Memes that make money</div>
                    <div className={s.moneyText}>
                        Viewers buy your virtual currency to
                        send stickers and donations. Reward
                        them for watching or joining contests,
                        and gift them branded items for their
                        Streamoji avatars.
                    </div>
                </div>
            </div>

            <div className={s.join}>
                <div className={s.joinTitle}>Hyperclap setup is quicker than reading this PAGE.</div>

                <Button
                    className={s.joinButton}
                    caption="Join now"
                    color={EComponentColor.ACCENT}
                    onClick={handleJoinClick}
                />

                <div className={s.joinPicture}/>
            </div>

            <Footer
                {...props}
                className={s.footer}
                hideThemeSwitch={true}
                isLogoGrayscale={false}
                isLogoOpacity={false}
                isSupportEnabled={false}
                isCopyRightEnabled={true}
                logoKind={LogoKind.DARK}
            />
        </div>
    );
};
