export enum EtmaRating {
    E,
    T,
    M,
    A,
}

export enum StickerSize {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum StickerSendTopic {
    DEFAULT = 'Default',
    SEARCH = 'Search',
    TOP = 'Top',
    POPULAR = 'Popular',
    LAST = 'Last',
    CHANNEL = 'Channel',
    FAVORITES = 'Favorites',
    MY_CREATED = 'MyCreated',
    THEMATIC_SET = 'Theme',
}

export interface IVideoData {
    width: number;
    height: number;
    aspectRatio: number;
    isTransparent: boolean;
}

export interface IStickerCustomSettings {
    _id?: string; // TODO: Remove it
    disableSticker?: boolean;
    customPriceEnabled?: boolean;
    customPrice?: number;
    customVolumeEnabled?: boolean;
    customVolume?: number;
}

export interface ITag {
    id?: string;
    name: string;
    usageCount?: number,
    createdAt?: number;
}

export interface IGame {
    id: string;
    name: string;
    coverThumbId?: string,
}

export interface ISticker {
    id: string;
    name: string;
    description: string;
    stickerUrl?: string;
    fallbackUrl?: string;
    stickerPreview? : string;
    stickerAnimatedPreview? : string;
    creatorId: string;
    streamerId?: string;
    gameId?: string;
    usageCount?: number;
    moderationState: string;
    isAdult?: boolean;
    isNotTwitchSafe?: boolean;
    hasCopyright?: boolean;
    isOnTop?: boolean;
    isFavorite?: boolean;
    likedCount?: number;
    isAddedToChannel?: boolean;
    categories?: string[];
    createdAt?: number;
    creatorName?: string;
    creatorAvatar?: string;
    moderatorName?: string;
    moderatorAvatar?: string;
    streamerName?: string;
    streamerAvatar?: string;
    gameName?: string;
    gameCoverThumbId?: string;
    customSettings?: IStickerCustomSettings;
    videoData?: IVideoData;
    etmaRating?: EtmaRating;
    disableCount?: number;
    disabledAt?: number;
    creatorTotalApproved?: number;
    creatorTotalDeclined?: number;
    tags?: ITag[];
    speech2text?: string;
    ocrText?: string;
    music?: string;
    isAdultProb?: number;
}

export interface IStickerCreationParams {
    name: string;
    description: string;
    streamerId?: string;
    gameId?: string;
    tags?: ITag[];
    token: string;
}

export interface IFavoriteStickersLoadParams {
    onlySent?: boolean;
    onlyFavorites?: boolean;
}

export interface IChannelStickersLoadParams {
    onlyBlackList?: boolean;
}

export interface IStickerCustomSettingsUpdateParams {
    stickerId: string;
    stickerCustomSettings: IStickerCustomSettings;
}

export interface ISendStickerParams {
    toChannel: string;
    fullscreen?: boolean;
    stickerId: string;
    topic: StickerSendTopic,
    name?: string;
    isMemePartyActive?: boolean;
    message?: string;
    isSoundOnly?: boolean;
}

export interface ISendMemeCannonStickerParams {
    toChannel: string;
    stickerId: string;
    topic: StickerSendTopic,
    count: number;
    name?: string;
    isMemePartyActive?: boolean;
    message?: string;
    isSoundOnly?: boolean;
}
