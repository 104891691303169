import {
    Column,
    Loader,
    useModals,
    IModalContentWithOptions,
} from '@hyperclap/ui';
import React, { useEffect } from 'react';

import { IconSmileGrinSolid } from '@assets/images/svg';
import { TApp, useAppActions, useElementOnScreen } from '@hooks';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker } from '@typings';
import { Empty, StickerPopup, StickersGrid } from '@views';

import s from './Desktop.scss';


interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const { showModal } = useModals();
    const {
        personalArea: {
            mine: {
                isPageLoadingAllowed,
                isStickersFetching,
                stickers,
                loadNextPage,
            },
        },
        stickers: {
            switchStickerFavoriteState,
            switchStickerInChannelState,
            changeStickerCustomSettings,
            deleteSticker,
        },
        translation: {
            t,
        },
    } = props;

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const onStickerClick = (a: ISticker) => {
        const stickerPopup: IModalContentWithOptions<ISticker> = {
            content: (
                <StickerPopup
                    {...props}
                    sticker={a}
                    updateCustomSettings={changeStickerCustomSettings}
                    updateFavoriteState={switchStickerFavoriteState}
                    updateInChannelState={switchStickerInChannelState}
                    deleteSticker={deleteSticker}
                />
            ),
            options: {
                className: s.noBgModal,
                bodyClassName: s.noPaddingModal,
            },
        };
        showModal(stickerPopup);
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    return (
        <Column margin={{ top: 23 }}>
            { stickers.length > 0
                ? <StickersGrid
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isStickersFetching
                    ? <Loader margin={{ top: 70 }} />
                    : <Empty
                        padding={{ top: 70 }}
                        icon={<IconSmileGrinSolid/>}
                        iconSize={50}
                        text={t('personal-area.mine.noStickers')}
                    />
            }
            {isPageLoadingAllowed &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
