import { Column, EComponentSize, ITextFieldMethods, Loader, Paginator, Row, TextField } from '@hyperclap/ui';
import React, { useEffect, useRef } from 'react';

import { IconMagnifierLine, IconUserCircleSolid } from '@assets/images/svg';
import { isNotUndefined, supporterValueText } from '@common';
import { TApp } from '@hooks';
import { ISupporter } from '@typings';
import { Empty } from '@views';

import s from './Desktop.scss';
import { SupportersTable } from '../../components';

interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        currentUser: {
            currentUser,
        },
        navigation: {
            goToSupporter,
        },
        personalArea: {
            supporters: {
                isSupportersFetching,
                searchQuery,
                supporters,
                supportersCount,
                pagesCount,
                changePage,
                setSearchQuery,
            },
        },
        translation: {
            t,
        },
    } = props;

    const searchFieldMethods = useRef<ITextFieldMethods>(null);

    const onSupportersPageChanged = (page: number) => changePage(page);

    const onSupporterSelected = (sup: ISupporter) => {
        goToSupporter(sup.supporterId);
    };

    useEffect(() => {
        searchFieldMethods.current?.set(searchQuery);
    }, [searchQuery]);

    useEffect(() => {
        return () => setSearchQuery('');
    }, []);

    return (
        <Column grow margin={{ top: 26 }} rowGap={20}>
            <Row className={s.header}>
                <Column rowGap={10}>
                    <Row>
                        {t('supporters.supporters')}
                    </Row>
                    <Row className={s.counterDescription}>
                        {supportersCount} {supporterValueText(supportersCount)}
                    </Row>
                </Column>
                <Column grow/>
                <Column>
                    <TextField
                        debounceTime={500}
                        methodsRef={searchFieldMethods}
                        placeholder={t('supporters.find')}
                        tag={<IconMagnifierLine/>}
                        width={250}
                        className={s.supportersSearch}
                        onChanged={setSearchQuery}
                    />
                </Column>
                { pagesCount > 1 &&
                    <Column>
                        <Paginator
                            page={1}
                            pagesCount={pagesCount}
                            showNavigation
                            onChangePage={onSupportersPageChanged}
                        />
                    </Column>
                }
            </Row>
            <Row>
                {currentUser &&
                    (isSupportersFetching
                        ? <Loader size={EComponentSize.MEDIUM}/>
                        : supporters?.length === 0
                            ? <Empty
                                padding={{ top: 70 }}
                                icon={<IconUserCircleSolid/>}
                                text={'Здесь будет список зрителей,\nкоторые присылают вам стикеры.'}
                                iconSize={EComponentSize.EXTRA_LARGE}
                            />
                            : isNotUndefined(supporters)
                                ? <SupportersTable
                                    currentUser={currentUser}
                                    data={supporters}
                                    onSupporterClick={onSupporterSelected}
                                />
                                :null
                    )
                }
            </Row>
        </Column>
    );
};
