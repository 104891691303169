import { Button, Column, EComponentColor, EComponentSize, Label, Loader } from '@hyperclap/ui';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { IconSmileSadSolid } from '@assets/images/svg';
import { STICKER_SEARCH_QUERY_NAME } from '@common';
import { useAppActions, useAppContext, useElementOnScreen } from '@hooks';
import { useAppSelector } from '@hooks/store/AppSelector';
import { ISticker, StickerSendTopic } from '@typings';
import { Empty, StickersGrid } from '@views';

import s from './SearchPage.scss';

export const SearchPage = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get(STICKER_SEARCH_QUERY_NAME) ?? '';

    const app = useAppContext();
    const {
        currentUser: {
            currentUser,
        },
        deviceInfo: {
            isAppleMobileDevice,
            isMobileLayoutRecommended,
        },
        navigation: {
            goToStickerCreation,
        },
        stickers: {
            setStickerToSend,
        },
        streamer: {
            streamer,
        },
        streamerArea: {
            stickers: {
                search: {
                    isFetching,
                    isLoadingAllowed,
                    searchQuery,
                    stickers,
                    loadNextPage,
                    changeSearchQuery,
                },
            },
        },
        translation: {
            t,
        },
    } = app;

    const playSoundOnStickerHover = useAppSelector((state) => state.app.playSoundOnStickerHover);
    const { switchPlaySoundOnStickerHover } = useAppActions();

    const {
        elementRef: watchdogRef,
        isOnScreen: needToLoadPage,
    } = useElementOnScreen<HTMLDivElement>({ threshold: 0 });

    const onStickerClick = (sticker: ISticker) => {
        if (streamer) {
            setStickerToSend(sticker, streamer, StickerSendTopic.SEARCH);
        }
    };

    useEffect(() => {
        if (needToLoadPage) {
            void loadNextPage();
        }
    }, [needToLoadPage]);

    useEffect(() => {
        if (query !== searchQuery) {
            changeSearchQuery(query);
        }
    }, [query]);

    const containerPadding = { left: isMobileLayoutRecommended ? 8 : 14, right: isMobileLayoutRecommended ? 8 : 4 };

    const emptyExtraContent = !isMobileLayoutRecommended
        ? <Column padding={{ top: 20 }}>
            <Button
                caption={t('common.create')}
                color={EComponentColor.ACCENT}
                width={160}
                onClick={goToStickerCreation}
            />
        </Column>
        : undefined;

    return (
        <Column grow padding={containerPadding}>
            <Label caption={'Результаты поиска'} size={EComponentSize.LARGE} useBoldFont/>
            {stickers.length > 0
                ? <StickersGrid
                    isAppleMobileDevice={isAppleMobileDevice}
                    isMobileLayoutRecommended={isMobileLayoutRecommended}
                    stickers={stickers}
                    stickersSoundEnabled={playSoundOnStickerHover}
                    defaultSoundVolume={currentUser?.channel?.soundVolume}
                    onStickerClick={onStickerClick}
                    onSwitchSound={switchPlaySoundOnStickerHover}
                />
                : isFetching
                    ? <Loader className={s.loader}/>
                    : <Empty
                        padding={{ top: 70 }}
                        icon={<IconSmileSadSolid/>}
                        iconSize={50}
                        text={t('streamer-area.search.noStickers')}
                        content={emptyExtraContent}
                    />
            }

            {isLoadingAllowed &&
                <div className={s.nextPageWatchdog}>
                    <div className={s.nextPageWatchdogMarker} ref={watchdogRef}/>
                </div>
            }
        </Column>
    );
};
